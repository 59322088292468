<template>
  <div>
    <Header :headerIndex="10"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner10.png" alt="" />
      <div class="blog_wrap">
        <div class="blog_inner max_1440">
          <!-- <div class="search_box flex-box">
            <input type="text" @change="searchblog" v-model="key" class="search_input flex-grow-1" placeholder="Please enter the search content" />
            <img class="icon_search" src="../assets/static/icon/icon_search.png" alt="" />
          </div> -->

          <div class="g-select-wrap">
            <el-select class="g-select mr30" v-model="seriesValue" placeholder="Select Product Series" @click.native="getserise" >
              <el-option @click.native="seriesclear" value="">Select Product Series</el-option>
              <el-option v-for="(item,index) in seriesOptions" :key="index" :label="item.name_en" :value="index" @click.native="getseriseid(item.id)"> </el-option>
            </el-select>
            <el-select class="g-select" v-model="colorValue" placeholder="Choose Color Model" @click.native="getcolor">
              <el-option @click.native="colorclear" value="">Choose Color Model</el-option>
              <el-option v-for="(item,index) in colorOptions" :key="index" :label="item.name_en" :value="item.name" @click.native="getcolorid(item.id)"> </el-option>
            </el-select>
          </div>

          <div class="blog_list flex-box flex-wrap flex-col-start">
            <router-link :to="{path:'/PosterDetail',query:{posterid:item.id}}" class="blog_item" v-for="item in posterlist">
              <div
                class="img"
                :style="{'background-image':'url(' + item.image_url +')'}"
              ></div>
              <!-- https://images2.img.xilukeji.com/rwf_2023-02-08_63e39c5c5ed69.jpg-rwf_water_rw -->
              <div class="name m-ellipsis-l2">{{item.name_en}}</div>
              <!-- <div class="date">{{item.createtime_text}}</div> -->
            </router-link>
          </div>

          <div :style="{ display: display }">
            <Pagination :sumCount="total" :now="page" :perCount="per_page" @pageTurn="pageTurn"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import axios from 'axios';
import api from '../api';
export default {
  data() {
    return {
      total: 4, //页面总量
      per_page: 12, //页面限制
      page: 1, //页面页数
      display: "",
      seriesOptions:[],
      seriesValue: "",
      colorOptions:[],
      colorValue: "",
      colorid:0,
      seriesid:0,
      posterlist:[]
    };
  },
  created(){
    //初始化加载
    if(this.$route.query.typepid&&this.$route.query.typeid){
      var json = {
        page:this.page,
        limit:this.per_page,
        typepid:this.$route.query.typepid,
        typeid:this.$route.query.typeid,
        indexrecommend:0
      }
      axios.get('https://n.xilukeji.com/rwf/index.php/Shop/Index/typelist_2023').then((res)=>{
          // console.log(res)
          if(res.status){
            this.seriesOptions=res.data.data.type
            for(var i=0;i<this.seriesOptions.length;i++){
                if(this.seriesOptions[i]['id'] == this.$route.query.typepid){
                  this.seriesValue = this.seriesOptions[i]['name_en']
                  this.seriesid = this.seriesOptions[i]['id']
                  this.colorOptions = this.seriesOptions[i].child
                  for(var x=0;x<this.colorOptions.length;x++){
                    if(this.colorOptions[x]['id'] == this.$route.query.typeid){
                      this.colorValue = this.colorOptions[x]['name_en']
                      this.colorid = this.colorOptions[x]['id']
                    }
                  }
                }
            }
          }         
      })
      axios.post('https://n.xilukeji.com/rwf/index.php/Shop/Index/posterlist_2023',json).then(res=>{
          // console.log(res)
          if(res.data.count == 0){
            this.posterlist = res.data.info 
            this.total = 0
          }else{
            this.posterlist = res.data.info
            this.total = res.data.count
          } 
          if(this.total<=this.per_page){
              this.display = 'none'
          }else{
            this.display = 'block'
          }  
      })
    }else{
      this.getposter()
    }
  },
  methods:{
    //页码
    pageTurn(page) {
      console.log(page)
      this.page = page+1;
      var json = {
        page:this.page,
        limit:this.per_page,
        typepid:this.seriesid,
        typeid:this.colorid
      }
      axios.post('https://n.xilukeji.com/rwf/index.php/Shop/Index/posterlist_2023',json).then(res=>{
          // console.log(res)
          if(res.data.count == 0){
            this.posterlist = []
            this.total = 1
          }else{
            this.posterlist = res.data.info
            this.total = parseInt(res.data.count)
          }  
          if(this.total<=this.per_page){
              this.display = 'none'
          }else{
            this.display = 'block'
          }      
      })
    },
    //获取series列表
    getserise(){
      axios.get('https://n.xilukeji.com/rwf/index.php/Shop/Index/typelist_2023').then((res)=>{
          // console.log(res)
          if(res.status){
            this.seriesOptions=res.data.data.type
          }         
      })
    },
    //获取颜色列表
    getcolor(){ 
      if(this.seriesid>0&&this.seriesValue !== ''){
        // console.log(this.seriesValue)
        this.colorOptions = this.seriesOptions[this.seriesValue].child
      }else{
        this.colorOptions = []
        this.colorValue='Choose Color Model'
      }
    },
    getseriseid(id){
        this.seriesid = id
        this.page=1
        console.log('this.page',this.page);
        this.colorValue =''
        this.colorid = 0  
        this.getposter()
    },
    seriesclear(){
      this.seriesid = 0   //typepid = 0
      this.colorid = 0    //typeid = 0
      this.seriesValue = ''
      this.getcolor()
      this.getposter()
    },  
    getcolorid(id){
      // console.log('colorid',id)
      this.colorid = id
      this.page =1
      this.getposter()
    },
    colorclear(){
      this.colorid = 0,
      this.colorValue = ''
      this.getposter()
    },
    //获取海报
    getposter(){
      this.pageTurn(0)
    }
  }
};
</script>
<style scoped>
.blog_wrap {
  padding: 30px 0;
}

.search_box {
  margin: 0 0 30px;
  padding: 0 20px;
  width: 397px;
  height: 70px;
  background: #333333;
  border-radius: 20px;
}
.search_box .search_input {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}
.search_box .icon_search {
  margin-left: 10px;
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.blog_list .blog_item {
  margin: 0 30px 30px 0;
  width: calc((100% - 60px) / 3);
}
.blog_list .blog_item:nth-of-type(3n) {
  margin: 0 0 30px;
}
.blog_list .img {
  display: block;
  width: 100%;
  height: 388px;
  border-radius: 20px 20px 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.blog_list .name {
  margin: 10px 0 0;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
}
.blog_list .date {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 17px;
}

.g-select-wrap{
  padding: 0 0 30px!important;
}

@media screen and (max-width: 1440px) {
  .blog_wrap {
    padding: 20px 0;
  }

  .search_box {
    margin: 0 0 22px;
    padding: 0 15px;
    width: 298px;
    height: 52px;
    border-radius: 15px;
  }
  .search_box .search_input {
    font-size: 12px;
    line-height: 23px;
  }

  .blog_list .blog_item {
    margin: 0 22px 22px 0;
    width: calc((100% - 44px) / 3);
  }
  .blog_list .blog_item:nth-of-type(3n) {
    margin: 0 0 22px;
  }

  .blog_list .img {
    height: 291px;
  }
  .blog_list .name {
    margin: 8px 0 0;
    font-size: 14px;
    line-height: 19px;
  }
  .blog_list .date {
    font-size: 10px;
    line-height: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .blog_wrap {
    padding: 30px 125px;
  }

  .search_box {
    margin: 0 0 16px;
    padding: 0 11px;
    width: 240px;
    height: 37px;
    border-radius: 11px;
  }

  .blog_list .blog_item {
    margin: 0 16px 16px 0;
    width: calc((100% - 32px) / 3);
  }
  .blog_list .blog_item:nth-of-type(3n) {
    margin: 0 0 16px;
  }
  .blog_list .img {
    height: 208px;
  }
}
@media screen and (max-width: 768px) {
  .blog_wrap {
    padding: 10px 90px;
  }

  .search_box .icon_search {
    width: 14px;
    height: 14px;
  }

  .blog_list .blog_item {
    margin: 0 12px 12px 0;
    width: calc((100% - 24px) / 3);
  }
  .blog_list .blog_item:nth-of-type(3n) {
    margin: 0 0 12px;
  }
  .blog_list .img {
    height: 155px;
  }
}

@media screen and (max-width: 767px) {
  .blog_wrap {
    padding: 15px;
  }

  .search_box {
    width: 100%;
    height: 37px;
  }

  .blog_list .blog_item {
    margin: 0 15px 15px 0;
    width: calc((100% - 15px) / 2);
  }
  .blog_list .blog_item:nth-of-type(3n) {
    margin: 0 15px 15px 0;
  }
  .blog_list .blog_item:nth-of-type(2n) {
    margin: 0 0 15px !important;
  }
  .blog_list .img {
    height: 158px;
  }

  .g-select-wrap{
    padding: 0 0 15px!important;
  }
}


</style>
